<template>
    <div class="font-stolzl h-auto flex flex-col md:flex-row md:justify-between md:items-start md:w-screen">
      
      <!-- TOP // LEFT -->
      <div class="w-full md:w-1/2 h-screen md:pt-16 flex flex-col">
        <div class="w-full min-h-full p-2 flex flex-col bg-coolGray-100  pb-32 md:pb-0">
          <div class="h-auto overflow-y-auto flex flex-col">
            <cartLines />
          </div>
        </div>        
      </div>
      <!-- TOP // LEFT -->
    
      <!-- BOTTOM // RIGHT -->
      <div class="hidden md:sticky h-screen top-0 w-full md:w-1/2 select-none md:flex flex-row justify-center items-center">
        <div class="h-full flex flex-col justify-center items-start w-5/6 md:w-3/4">
          <div class="text-xl text-coolGray-900">{{text.cart}}</div>
          <div v-if="cart.items.length > 0" class="flex flex-col justify-start items-start">
            <div class="mt-2 text-sm font-light mr-1.5">{{text.total1}} {{cartTotal.qty}} {{text.total2}} {{cartTotal.total}}</div>
            <div v-if="cartTotal.trees == 1" class=" text-sm font-light">{{text.trees1sing}} {{cartTotal.trees}} {{text.trees2sing}}</div>
            <div v-else-if="cartTotal.trees > 0" class=" text-sm font-light">{{text.trees1plur}} {{cartTotal.trees}} {{text.trees2plur}}</div>

          </div>
          <div v-else class="font-light text-coolGray-900">{{text.cartEmpty}}</div>
          
          <router-link v-if="cart.items.length > 0" :to="{name: 'Checkout'}" class="transition duration-500 ease-in-out w-full bg-coolGray-900 h-16 mt-3 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer hover:bg-white hover:text-coolGray-900 border-2">
            <div>{{text.toCheckout}}</div>
          </router-link>
          <div v-if="cart.items.length > 0" class="mt-2 text-sm font-light">
            <div v-if="channel == 'b2b'">{{text.infob2b}}</div>
            <div v-else>{{text.infob2c}}</div>
          </div> 
        </div>
      </div>
      <!-- BOTTOM // RIGHT -->
      
    </div>
</template>

<script>
import cartLines from '../components/cartLines.vue';
import scrollToTop from '../mixins/scrollToTop.vue';
import formatPrice from '../mixins/formatPrice.vue';

export default {
  name: 'Cart',
  components: { cartLines },
  mixins: [ scrollToTop, formatPrice ],

  computed: {
    text(){
      return this.$store.state.lang.cart[this.$store.state.lang.currentLang];
    },
    cart(){
      return this.$store.state.data.cart;
    },
    channel(){
      return this.$store.state.data.channel
    },
    cartTotal(){
      var total = 0;
      var totalQty = 0;
      var trees = 0;
      this.cart.items.forEach(function(item){
        total = total + item.total;
        totalQty = totalQty + item.qty;
        var metaIndex = item.meta.findIndex(x => x.key == 'trees');
        if(metaIndex != -1){
          var metaVal = item.meta[metaIndex].value;
          trees = trees + metaVal * item.qty;
        }
      });
      return {total: this.formatPrice(total), qty: totalQty, trees: trees};
    }
  },
}
</script>
