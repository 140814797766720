<template>
  <div v-for="item in cart.items" :key=item class="flex flex-row md:justify-around justify-start items-center w-full font-stolzl font-light text-sm mt-5 md:px-8 text-coolGray-900">

    <!-- IMAGE -->
    <router-link v-if="this.$route.name == 'Cart'" :to="{name: 'Products', params:{ productId: item.id, slug: item.slug}}" class="relative w-32 h-32 flex-shrink-0">
      <div class="absolute z-30 w-full h-full  bg-local bg-center bg-no-repeat bg-contain" :style="{ backgroundImage: 'url(' + item.url + ')' }"></div>
      <transition @enter="shiftEnter" @leave="shiftLeave" :css="false">
        <div v-if="item.qty >= 2" class="absolute inset-0 ml-5 z-20 mt-2 h-30 w-30 bg-local bg-center bg-no-repeat bg-contain" :style="{ backgroundImage: 'url(' + item.url + ')' }"></div>
      </transition>
      <transition @enter="shiftEnter" @leave="shiftLeave" :css="false">
        <div v-if="item.qty >= 3" class="absolute inset-0 ml-11 z-10 mt-8 h-20 w-20 bg-local bg-center bg-no-repeat bg-contain" :style="{ backgroundImage: 'url(' + item.url + ')' }"></div>
      </transition>
    </router-link>

    <div v-else class="relative w-32 h-32 flex-shrink-0">
      <div class="absolute z-30 w-full h-full  bg-local bg-center bg-no-repeat bg-contain" :style="{ backgroundImage: 'url(' + item.url + ')' }"></div>
        <div v-if="item.qty >= 2" class="absolute inset-0 ml-5 z-20 mt-2 h-30 w-30 bg-local bg-center bg-no-repeat bg-contain" :style="{ backgroundImage: 'url(' + item.url + ')' }"></div>
        <div v-if="item.qty >= 3" class="absolute inset-0 ml-11 z-10 mt-8 h-20 w-20 bg-local bg-center bg-no-repeat bg-contain" :style="{ backgroundImage: 'url(' + item.url + ')' }"></div>
    </div>

    <div class="flex flex-col md:flex-row md:flex-grow md:justify-between ml-8 md:ml-0">

    <!-- NAME & DESCRIPTION -->
    <div class="hidden md:flex flex-col md:pl-5 select-none order-1">
      <div class="font-medium">{{item.name}}</div>
      <div>{{item.variantName}}</div>
    </div>

    <div class="md:hidden select-none flex flex-row">
      <div class="font-medium">{{item.name}},</div>
      <div class="ml-2">{{item.variantName}}</div>
    </div>

    <!-- QUANTITY -->
    <div v-if="this.$route.name == 'Cart'" class="flex flex-row  justify-start md:justify-center items-center flex-grow  md:mr-5 mt-3 md:mt-0 order-3 md:order-2">
      <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-6 w-6 mr-2 cursor-pointer text-coolGray-700 hover:text-coolGray-900" fill="none" viewBox="0 0 24 24" stroke="currentColor" @click="decrease(item.id, item.qty)">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <input v-model.number="item.qty" type="number" min="1" class="text-base md:text-sm transition duration-200 ease-out appearance-none w-20 text-center px-2 py-2 bg-white cursor-text rounded focus:outline-none focus:ring-2 focus:ring-coolGray-700" @input ="changeQty(item.id, item)"/>
      <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-6 w-6 ml-2 cursor-pointer text-coolGray-700 hover:text-coolGray-900" fill="none" viewBox="0 0 24 24" stroke="currentColor" @click="increase(item.id)">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    </div>
    <div v-else class="w-1/3 flex flex-row justify-center items-center order-2">
      <div>{{item.qty}} St.</div>
      <div v-if="item.quantityAvailable" class="text-red-600 ml-5">VERFÜGBAR: {{item.quantityAvailable}}</div>
    </div>

    <!-- TOTAL -->
    <div class="flex flex-col justify-center items-start md:items-center w-full md:w-1/6  order-2 md:order-3">
      <div v-if="item?.meta?.length > 0 && item.meta.some(m => m.key === 'originalPrice' + channel)" class="flex flex-row justify-center items-center">
        <div class="select-none text-red-600 mr-2">{{formatPrice(item.total)}}</div>
        <div class="text-xs text-coolGray-700 select-none line-through">{{formatPrice(item.meta[item.meta.findIndex(m => m.key === 'originalPrice' + channel)].value * item.qty)}}</div>
      </div>
      <div v-else>
        <div class="select-none text-coolGray-900">{{formatPrice(item.total)}}</div>
      </div>
      <div v-if="item.total !== item.price" class=" text-xs text-coolGray-700 select-none">({{formatPrice(item.price)}} / St.)</div>
    </div>

    </div>

    <!-- DELETE -->
    <svg v-if="this.$route.name == 'Cart'" xmlns="http://www.w3.org/2000/svg" class="hidden md:block h-6 w-6 cursor-pointer ml-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" @click="removeItem(item.id)">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M6 18L18 6M6 6l12 12" />
    </svg>

  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';
import formatPrice from '../mixins/formatPrice.vue';

export default {
  name: 'cartLines',
  mixins: [formatPrice],

  computed: {
    cart() {
      return this.$store.state.data.cart;
    },
    channel() {
      return this.$store.state.data.channel;
    },
    cartTotal() {
      let total = 0;
      let totalQty = 0;
      this.cart.items.forEach((item) => {
        total += item.total;
        totalQty += item.qty;
      });
      return { total: this.formatPrice(total), qty: totalQty };
    },
  },

  methods: {

    // ----------------------------------------------//
    // ------------------ANIMATIONS------------------//
    // ----------------------------------------------//

    shiftEnter(el, done) {
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 350,
        opacity: [0, 1],
        translateX: '0rem',
        complete: done,
        // eslint-disable-next-line no-unused-vars
        begin(anim) {
          '-2rem';
        },
      });
    },

    shiftLeave(el, done) {
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 350,
        opacity: [1, 0],
        translateX: '0rem',
        complete: done,
      });
    },

    // ----------------------------------------------//
    // ------------------METHODS---------------------//
    // ----------------------------------------------//

    increase(id) {
      this.$store.commit('increaseQuantity', id);
    },

    decrease(id, qty) {
      console.log(qty);
      if (qty !== 1 || window.innerWidth <= 768) {
        this.$store.commit('decreaseQuantity', id);
      }
    },

    removeItem(id) {
      this.$store.commit('removeItem', id);
    },

    changeQty(id, item) {
      if (item.qty === '' || item.qty < 0) {
        this.$store.commit('resetQty', id);
      } else {
        this.$store.commit('changeQty', { id, qty: item.qty });
      }
    },

  },
};
</script>

<style>
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
